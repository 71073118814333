<template>
  <div>
    <h1 class="mb-8 font-bold text-3xl">Dashboard</h1>    
    <div class="lg:flex lg:flex-row">
      <div class="m-8 shadow-xl flex-1" v-if="$page.props.auth.user.perm.records.is_mis_admin" >
        <HighChartPie                    
                    :title=this.pie_chart_title
                    :label=this.pie_chart_label
                    :data=this.pie_chart_data
                    :total=this.pie_chart_total></HighChartPie>
      </div>
      <div class="m-8 shadow-xl flex-1">
        <HighChartPie 
                    :title=this.gender_chart_title
                    :label=this.gender_chart_label
                    :data=this.gender_chart_data
                    :total=this.gender_chart_total></HighChartPie>
      </div>
    </div>
    <div class="lg:grid">
      <HighChartStackTarget class="m-8 shadow-xl col-span-2"
                    v-for="item in this.stack_chart_data.items" :key="item.key"
                    :title=item.key
                    :series=item.series
                    :label=stack_chart_data.label
                    :categories=item.categories>
      </HighChartStackTarget>
    </div>
  </div>
</template>

<script>
import Layout from '@/Shared/Layout'

import HighChartBasic from '@/Shared/HighChartBasic'
import HighChartPie from '@/Shared/HighChartPie'
import HighChartStack from '@/Shared/HighChartStack'
import HighChartStackTarget from '@/Shared/HighChartStackTarget'

import { EventBus } from "@/event-bus.js";

export default {
  metaInfo: { title: 'Malaria Results Dashboard' },
  props: {
    pie_chart_title: String,
    pie_chart_label: String,
    pie_chart_data: Array,
    pie_chart_total: Number,
    gender_chart_title: String,
    gender_chart_label: String,
    gender_chart_data: Array,
    gender_chart_total: Number,
    stack_chart_data: Array,
  },
  components: {
    HighChartBasic,
    HighChartPie,
    HighChartStack,
    HighChartStackTarget,
  },
  data() {
    return {
    }
  },
  layout: Layout,
  mounted() {
    // Use event bus to avoid page reload
    EventBus.$on('chartClicked', url => {
      this.$inertia.get(url)
    });
  },
}
</script>
