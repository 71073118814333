<template>
  <div>
    <h1 class="mb-8 font-bold text-3xl">{{ pageName }}</h1>
    <div class="mb-6 flex justify-between items-center">
      <inertia-link v-if="$page.props.auth.user.perm.records.edit" class="btn-indigo" :href="route(urlName + '.create')">
        <span>Create</span>
        <span class="hidden md:inline">{{ pageName }}</span>
      </inertia-link>
      <search-filter v-if="$page.props.auth.user.perm.records.edit" v-model="form.search" class="w-full max-w-md mr-4" @reset="reset">
        <label class="block text-gray-700">Trashed:</label>
        <select v-model="form.trashed" class="mt-1 w-full form-select">
          <option :value="null" />
          <option value="with">With Trashed</option>
          <option value="only">Only Trashed</option>
        </select>
      </search-filter>
      <search v-else v-model="form.search" class="w-full max-w-md mr-4" @reset="reset">
      </search>
      
    </div>
    <div class="bg-white rounded-md shadow overflow-x-auto">
      <table class="w-full whitespace-nowrap">
        <tr class="text-left font-bold">
          <th class="px-6 pt-6 pb-4 cursor-pointer" @click="sort('short_version')"><sort-icon fieldName="short_version" :sortBy="form.sort_by" :sortDirection="form.sort_direction">Title</sort-icon></th>
          <th class="px-6 pt-6 pb-4 cursor-pointer" @click="sort('target')"><sort-icon fieldName="target" :sortBy="form.sort_by" :sortDirection="form.sort_direction">Target</sort-icon></th>
        </tr>
        <tr v-for="(varObj, objKey) in varObjs.data" :class="varObj.deleted_at !== null ? 'bg-red-100' : objKey % 2 == 0 ? 'bg-sky-50' : '' " :key="varObj.id" class="hover:bg-yellow-100 focus-within:bg-gray-100">
          <td class="border-t">
            <inertia-link v-if="$page.props.auth.user.perm.records.is_mis_admin" class="px-6 py-4 flex items-center" :href="route(urlName + '.edit', varObj.id)" tabindex="-1">
              <div v-if="varObj.short_version">
                {{ varObj.short_version }}
              </div>
            </inertia-link>
            <inertia-link v-else class="px-6 py-4 flex items-center" :href="route(urlName + '.show', varObj.id)" tabindex="-1">
              <div v-if="varObj.short_version">
                {{ varObj.short_version }}
              </div>
            </inertia-link>
          </td>
          <td class="border-t">
            <inertia-link v-if="$page.props.auth.user.perm.records.is_mis_admin" class="px-6 py-4 flex items-center" :href="route(urlName + '.edit', varObj.id)" tabindex="-1">
              <div v-if="varObj.target">
                {{ varObj.target }}
              </div>
            </inertia-link>
            <inertia-link v-else class="px-6 py-4 flex items-center" :href="route(urlName + '.show', varObj.id)" tabindex="-1">
              <div v-if="varObj.target">
                {{ varObj.target }}
              </div>
            </inertia-link>
          </td>
          <td class="border-t w-px">
            <inertia-link v-if="$page.props.auth.user.perm.records.is_mis_admin" class="px-4 flex items-center" :href="route(urlName + '.edit', varObj.id)" tabindex="-1">
              <icon name="cheveron-right" class="block w-6 h-6 fill-gray-400" />
            </inertia-link>
            <inertia-link v-else class="px-4 flex items-center" :href="route(urlName + '.show', varObj.id)" tabindex="-1">
              <icon name="cheveron-right" class="block w-6 h-6 fill-gray-400" />
            </inertia-link>
          </td>
        </tr>
        <tr v-if="varObjs.data.length === 0">
          <td class="border-t px-6 py-4" colspan="4">No resuls found.</td>
        </tr>
      </table>
    </div>
    <div class="justify-between lg:flex" v-if="varObjs.total > 0">
      <div class="mt-6 mr-5 lg:shrink-0 ">
        <div class="mb-1 px-4 py-2 text-sm leading-4 bg-white border rounded"> Showing {{ varObjs.from + ' to ' + varObjs.to + ' of ' + varObjs.total }} entries </div>
        <div>
          <select-input class="text-sm lg:shadow-inner rounded-lg" v-model="form.page_len" label="">
              <option :value="10">10 rows per page</option>
              <option :value="20">20 rows per page</option>
              <option :value="50">50 rows per page</option>
              <option :value="100">100 rows per page</option>
          </select-input>
        </div>
      </div>
      <pagination class="mt-6" :links="varObjs.links" />
    </div>
  </div>
</template>

<script>
import Icon from '@/Shared/Icon'
import pickBy from 'lodash/pickBy'
import Layout from '@/Shared/Layout'
import throttle from 'lodash/throttle'
import mapValues from 'lodash/mapValues'
import Pagination from '@/Shared/Pagination'
import Search from '@/Shared/Search'
import SearchFilter from '@/Shared/SearchFilter'
import SelectInput from '@/Shared/SelectInput'
import SortIcon from '@/Shared/SortIcon'

export default {
  metaInfo() {
    return {
      title: `${this.pageName}`,
    }
  },
  components: {
    Icon,
    Pagination,
    Search,
    SearchFilter,
    SelectInput,
    SortIcon,
  },
  layout: Layout,
  props: {
    filters: Object,
    page_len: Number,
    varObjs: Object,
    pageName: String,
    urlName: String,
  },
  data() {
    return {
      form: {
        search: this.filters.search,
        trashed: this.filters.trashed,
        sort_by: 'short_version',
        sort_direction: 'ASC',
        page_len: this.page_len,
      },
    }
  },
  watch: {
    form: {
      deep: true,
      handler: throttle(function() {
        this.$inertia.get(this.route(this.urlName + '.index'), pickBy(this.form), { preserveState: true })
      }, 150),
    },
  },
  methods: {
    reset() {
      const page_len_temp = this.form.page_len

      this.form = mapValues(this.form, () => null)

      // Reset sorting
      this.form.sort_by = 'short_version'
      this.form.sort_direction = 'ASC'
      this.form.page_len = page_len_temp
    },
    sort(column) {
      this.form.sort_by = column
      this.form.sort_direction = this.form.sort_direction == 'ASC' ? 'DESC' : 'ASC'
    }
  },
}
</script>
