<template>
	<div v-if="cookieBanner.isOpen" class="cookieBanner bg-indigo-600 fixed bottom-0 w-full text-white p-4">
		<div class="container mx-auto flex items-center justify-between">
			<div>
				This website uses cookies and similar technologies to understand visitor experiences. By using this website, you consent to UNC-Chapel Hill's cookie usage in accordance with their <a href="https://www.unc.edu/about/privacy-statement/" target="_blank" class="underline">Privacy Notice</a>.
			</div>
			<button @click="closeBanner()" class="closeButton whitespace-nowrap bg-indigo-800 py-3 pl-4 pr-6 rounded-lg hover:bg-blue-500 text-white font-semibold">I Accept</button>
		</div>
	</div>
</template>  

<script>
import axios from 'axios';

export default {
	data() {
		return {
			cookieBanner: {
				isOpen: false,
			},
		};
	},
	methods: {
		async getCookies() {
			try {
				const response = await axios.get(route('cookie.get'));
				if (response.data.banner === 'ResultSystem') {
					this.cookieBanner.isOpen = false;
				} else {
					this.cookieBanner.isOpen = true;
				}
			} catch (error) {
				// Handle any errors that might occur during the GET request
			}
		},
		async closeBanner() {
			try {
				await axios.get(route('cookie.set'));
				this.cookieBanner.isOpen = false;
			} catch (error) {
				// Handle any errors that might occur during the GET request
			}
		},
	},
	mounted() {
		this.getCookies();
	},
};
</script>

<style scoped>
.closeButton {
  	min-width: 60px;
}
</style>