<template>
  <div>
    <h1 class="mb-8 font-bold text-3xl">
      <inertia-link class="text-indigo-400 hover:text-indigo-600" :href="route('results.index')">Results</inertia-link>
      <span class="text-indigo-400 font-medium">/</span>
      {{ result.title }}
    </h1>
    <trashed-message v-if="result.deleted_at" class="mb-6" @restore="restore">
      This result has been deleted.
    </trashed-message>
    <div class="bg-white rounded-md shadow overflow-hidden">
      <div class="p-8 -mr-6 -mb-8 flex flex-wrap">
        <p class="pr-6 pb-4 w-full lg:w-1/4 lg:text-right font-medium">Title:</p>
        <p class="pr-6 pb-8 w-full lg:w-3/4">{{ result.title }}</p>
        <p class="pr-6 pb-4 w-full lg:w-1/4 lg:text-right font-medium">Narrative:</p>
        <p class="pr-6 pb-8 w-full lg:w-3/4">{{ result.narrative }}</p>
        <p class="pr-6 pb-4 w-full lg:w-1/4 lg:text-right font-medium">Status:</p>
        <p class="pr-6 pb-8 w-full lg:w-3/4 text-indigo-400">{{ this.status }}</p>
        <p class="pr-6 pb-4 w-full lg:w-1/4 lg:text-right font-medium">Indicator:</p>
        <p class="pr-6 pb-8 w-full lg:w-3/4" v-if="this.indicator">{{ this.indicator.short_version }}</p>
        <p class="pr-6 pb-8 w-full lg:w-3/4 list-disc list-inside" v-else>&nbsp;</p>
        <p class="pr-6 pb-2 w-full lg:w-1/4 lg:text-right font-medium">Result Areas:</p>
        <ul class="pr-6 pb-8 w-full lg:w-3/4 list-disc list-inside" v-if="this.resultarea_ids.length">
          <li class="pb-4" v-for="resultarea in this.resultarea_ids" :key="resultarea.id">
            {{ resultarea.short_version }}
          </li>
        </ul>
        <p class="pr-6 pb-8 w-full lg:w-3/4 list-disc list-inside" v-else>&nbsp;</p>
        <p class="pr-6 pb-2 w-full lg:w-1/4 lg:text-right font-medium">Country(ies):</p>
        <ul class="pr-6 pb-8 w-full lg:w-3/4 list-disc list-inside" v-if="this.country_ids.length">
          <li class="pb-4" v-for="country in this.country_ids" :key="country.country_id">
            {{ country.long_name }}
          </li>
        </ul>
        <p class="pr-6 pb-8 w-full lg:w-3/4 list-disc list-inside" v-else>&nbsp;</p>
        <p class="pr-6 pb-2 w-full lg:w-1/4 lg:text-right font-medium">Primary MEL Contact:</p>
        <p class="pr-6 pb-8 w-full lg:w-3/4"><span v-if="this.pmcontact">{{ this.pmcontact.first_name }} {{ this.pmcontact.last_name }}</span></p>
        <p class="pr-6 pb-8 w-full lg:w-1/4 lg:text-right font-medium">Gender:</p>
        <p class="pr-6 pb-8 w-full lg:w-3/4">{{ result.gender ? 'Yes' : 'No' }}</p>
        <p class="pr-6 pb-8 w-full lg:w-1/4 lg:text-right font-medium" v-show="showTrainingFields">No. of Females Trained:</p>
        <p class="pr-6 pb-8 w-full lg:w-3/4" v-show="showTrainingFields">{{ result.no_of_females_trained }}</p>
        <p class="pr-6 pb-8 w-full lg:w-1/4 lg:text-right font-medium" v-show="showTrainingFields">No. of Males Trained:</p>
        <p class="pr-6 pb-8 w-full lg:w-3/4" v-show="showTrainingFields">{{ result.no_of_males_trained }}</p>
        <p class="pr-6 pb-8 w-full lg:w-1/4 lg:text-right font-medium" v-show="showTrainingFields">No. of Unknown Gender Trained:</p>
        <p class="pr-6 pb-8 w-full lg:w-3/4" v-show="showTrainingFields">{{ result.no_of_unknown_gender_trained }}</p>
        <p class="pr-6 pb-8 w-full lg:w-1/4 lg:text-right font-medium">Total Number Trained:</p>
        <p class="pr-6 pb-8 w-full lg:w-3/4">{{ result.total_no_trained }}</p>
        <p class="pr-6 pb-8 w-full lg:w-1/4 lg:text-right font-medium">Year Result Met:</p>
        <p class="pr-6 pb-8 w-full lg:w-3/4"><span v-if="result.year_result_met.length">{{ result.year_result_met.join(', ') }}</span></p>
        <p class="pr-6 pb-8 w-full lg:w-1/4 lg:text-right font-medium">Year Result Reported:</p>
        <p class="pr-6 pb-8 w-full lg:w-3/4"><span v-if="result.year_result_reported.length">{{ result.year_result_reported.join(', ') }}</span></p>
        <p class="pr-6 pb-8 w-full lg:w-1/4 lg:text-right font-medium">Files:</p>
        <p class="pr-6 pb-8 w-full lg:w-3/4"><DataGridReadOnlyFileField :sections="fileuploads" :is_note_file="false"></DataGridReadOnlyFileField></p>
        <p v-if="$page.props.auth.user.perm.records.non_usaid_view" class="pr-6 pb-8 w-full lg:w-1/4 lg:text-right font-medium">Notes:</p>
        <p v-if="$page.props.auth.user.perm.records.non_usaid_view" class="pr-6 pb-8 w-full lg:w-3/4"><DataGridReadOnlyNoteFileField v-if="notes.length > 0" :sections="notes" class="pb-8 mt-4"></DataGridReadOnlyNoteFileField></p>
        <p class="pr-6 pb-2 w-full lg:w-1/4 lg:text-right font-medium">Associated Activities:</p>
        <ul class="pr-6 pb-8 w-full lg:w-3/4 list-disc list-inside">
          <li class="pb-4" v-for="activity in this.activity_ids" :key="activity.activity_id">
            <a class="text-indigo-400" :href="`${ activity_url_prefix }${ activity.activity_id }`" target='_blank'>{{ activity.activity_code }}</a>
          </li>
        </ul>
        <p class="pr-6 pb-2 w-full lg:w-1/4 lg:text-right font-medium">Contributing Benchmarks:</p>
        <ul class="pr-6 pb-8 w-full lg:w-3/4 list-disc list-inside">
          <li class="pb-4" v-for="benchmark in this.benchmark_ids" :key="benchmark.uuid">
            <a class="text-indigo-400" :href="`${ benchmark_url_prefix }${ benchmark.uuid }`" target='_blank'>{{ benchmark.title }}</a>
          </li>
        </ul>
        <p class="pr-6 pb-2 w-full lg:w-1/4 lg:text-right font-medium">Contributing Deliverables:</p>
        <ul class="pr-6 pb-8 w-full lg:w-3/4 list-disc list-inside">
          <li class="pb-4" v-for="deliverable in this.deliverable_ids" :key="deliverable.uuid">
            <a class="text-indigo-400" :href="`${ deliverable_url_prefix }${ deliverable.uuid }`" target='_blank'>{{ deliverable.title }}</a>
          </li>
        </ul>
        <p class="pr-6 pb-2 w-full lg:w-1/4 lg:text-right font-medium">Stakeholders:</p>
        <ul class="pr-6 pb-8 w-full lg:w-3/4 list-disc list-inside">
          <li class="pb-4" v-for="activitystakeholder in this.activitystakeholder_ids" :key="activitystakeholder.uuid">
            <a class="text-indigo-400" :href="`${ activitystakeholder_url_prefix }${ activitystakeholder.uuid }`" target='_blank'>{{ activitystakeholder.long_name }}</a>
          </li>
        </ul>   
      </div>
    </div>
  </div>
</template>

<script>
import Layout from '@/Shared/Layout'
import TrashedMessage from '@/Shared/TrashedMessage'
import DataGridReadOnlyFileField from '@/Shared/DataGridReadOnlyFileField'
import DataGridReadOnlyNoteFileField from '@/Shared/DataGridReadOnlyNoteFileField'

export default {
  metaInfo() {
    return {
    }
  },
  components: {
    TrashedMessage,
    DataGridReadOnlyFileField,
    DataGridReadOnlyNoteFileField,
  },
  layout: Layout,
  computed: {
    showTrainingFields: function () {
      if (this.indicator)
        return this.indicator.short_version.startsWith('A6')
      else
        return false
    }
  },
  props: {
    result: Object,
    indicator: Object,
    resultarea_ids: Array,
    country_ids: Array,
    pmcontact: Object,
    fileuploads: Array,
    notes: Array,
    benchmark_ids: Array,
    deliverable_ids: Array,
    activitystakeholder_ids: Array,
    needsuse_ids: Array,
    status: String,
    activity_ids: Array,
    activity_url_prefix: String,
    benchmark_url_prefix: String,
    deliverable_url_prefix: String,
    activitystakeholder_url_prefix: String,
    needsuse_url_prefix: String,
  },
  data() {
    return {
    }
  },
  methods: {
  },
}
</script>
